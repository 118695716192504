import { AuthContext } from "components/authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { DocumentData, getFirestore } from "firebase/firestore";
import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import styles from "./commissionsPage.module.css";
import { app, host } from "../../config";
import CircularProgress from "@mui/material/CircularProgress";
import CustomAlert from "components/alert/CustomAlert";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";

type Commissions = {
  name: string;
  invoice_month: string;
  invoice_year: string;
};

// Mock data - you would fetch this from an API in a real application
// const invoicesData: Invoice[] = [
//   {
//     name: "20039_378_012224_0622.ACE.pdf",
//     month: "February",
//     year: 2024,
//     pdfUrl: "/path/to/your/pdf/20039_378_012224_0622.ACE.pdf",
//   },
//   // ... other invoices
// ];

// const [searchGroupId, setSearchGroupId] = useState<string>("");
const auth = getAuth();
const db = getFirestore(app);

const Commissions: React.FC = () => {
  const [commissions, setCommissions] = useState<Commissions[]>([]);
  const [docData, setDocData] = useState<DocumentData | null>(null);
  const [agentBrokerId, setAgentBrokerId] = useState("");
  const [payeeNames, setPayeeNames] = useState({});
  const [inputValue, setInputValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const openAlert = () => {
    setAlertOpen(true);
  };

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, role, payees, selectedPayee, setSelectedPayee } = authContext;

  var tk: string;
  useEffect(() => {
    if (role === "broker") {
      setIsLoading(true);
      const passedPayees = selectedPayee;
      setAgentBrokerId(selectedPayee);
      currentUser
        ?.getIdToken(false)
        .then((idToken: string) => {
          tk = idToken;
          fetchCommissions(passedPayees);
          fetchPayeeNames();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [selectedPayee]);

  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (docData?.Suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (role === "employer") {
    return <Navigate to="/basePage" />;
  }

  if (role !== "admin" && role !== "broker" && role !== "employer" && role !== "internal_ugp") {
    return <Navigate to="/basePage" />;
  }

  const fetchCommissions = (passedFederalId: string) => {
    setIsLoading(true);
    if (!passedFederalId) passedFederalId = agentBrokerId;
    let params = new URLSearchParams();
    params.append("passedFederalId", passedFederalId);
    const url = `${host}/api/upload/listCommissions?${params}`;
    fetch(url, {
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        if (response.status === 201) {
          setIsLoading(false);
          await response.json().then((res) => {
            let formatted_commissions = res.files.flat().map((x) => {
              return {
                name: x.name.split("/").slice(-1)[0],
                invoice_month: convertMonthToName(x.name.split("_").slice(-2)[0]),
                invoice_year: x.name.split("_").slice(-1)[0].split(".")[0],
              };
            });
            setCommissions(formatted_commissions);
          });
        } else {
          setAlertMessage("No commission statements found for the selected broker.");
          setCommissions([]);
          setAlertOpen(true);
        }
      })
      .catch((err) => console.error("Error fetching invoices: ", err))
      .finally(() => setIsLoading(false));
  };

  const fetchPayeeNames = () => {
    setIsLoading(true);

    fetch(`${host}/api/upload/getPayeeNames`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.error) {
          payees.map((x: any) => {
            Object.assign(payeeNames, { x: "" });
          });
          setPayeeNames(payeeNames);
        } else {
          let payeeNames = {};
          responseData.payees.map((x: any) => {
            Object.assign(payeeNames, { [x["Federal_ID"]]: x["Name"] });
          });
          setPayeeNames(payeeNames);
        }
      })
      .catch((err) => {
        setAlertMessage(err);
        setAlertOpen(true);
      });
  };

  const downloadInvoice = (name: string) => {
    // Use the Fetch API to get the PDF file as a blob
    setIsLoading(true);
    fetch(`${host}/api/upload/downloadCommissions?passedFederalId=${agentBrokerId}&name=${name}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        setIsLoading(false);
        if (response.status === 201) {
          await response.arrayBuffer().then((resp) => {
            var blob = new Blob([resp], { type: "application/pdf" });
            var url = URL.createObjectURL(blob);
            window.open(url);
          });
        } else {
          await response.json().then((res) => {
            setAlertMessage(res.error);
            setAlertOpen(true);
          });
        }
      })
      .catch((err) => console.error("Error downloading file: ", err));
  };

  const convertMonthToName = (month: string) => {
    const monthsDict = {
      "1": "January",
      "2": "February",
      "3": "March",
      "4": "April",
      "5": "May",
      "6": "June",
      "7": "July",
      "8": "August",
      "9": "September",
      "10": "October",
      "11": "November",
      "12": "December",
    };
    if (monthsDict[month]) {
      return monthsDict[month];
    }
    return month;
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeaders: {
            "& .MuiDataGrid-sortIcon": {
              color: "white",
              "&:hover": {
                color: "white",
              },
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "white", // For other icon buttons
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
              color: "white",
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          selectIcon: {
            color: "black",
          },
          select: {
            color: "black",
          },
          actions: {
            "& .MuiIconButton-root": {
              color: "black", // Ensures the pagination arrows are always black
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
                color: "black", // Keeps the icon black on hover
              },
            },
          },
        },
      },
    },
  });

  const headCells: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "invoice_month",
      headerName: "Invoice Month",
      flex: 1,
      editable: false,
    },
    {
      field: "invoice_year",
      headerName: "Invoice Year",
      flex: 1,
      editable: false,
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: "8px", backgroundColor: "#004e9d" }}
            onClick={() => downloadInvoice(params.row.name)}
          >
            Download Invoice
          </Button>
        </Box>
      ),
      editable: false,
      headerClassName: "super-app-theme--header",
    },
  ];

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.commissionsView_body}>
      <Box className={styles.commissionsView_copy_box}>
        <h1>Manage and Download Claims Commissions for:</h1>
        <p>
          Please use the drop down below to select the broker you'd like to view commissions for. You may also search by
          name or Federal ID of the broker you wish to view commissions for. If the option does not appear, then you are
          not permitted to view the commission statements for that broker.
        </p>
      </Box>

      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />

      {role === "admin" || role === "internal_ugp" ? (
        <div className={styles.commissionsView_search_container}>
          <TextField
            className={styles.commissionsView_search_input}
            type="text"
            value={agentBrokerId}
            onChange={(e) => setAgentBrokerId(e.target.value)}
            placeholder="Enter Tax ID"
          />
          <Button
            className={styles.commissionsView_button}
            variant="contained"
            style={{ backgroundColor: "#004e9d", marginLeft: "4px", padding: "10px" }}
            onClick={() => {
              fetchCommissions(agentBrokerId);
            }}
          >
            Search
          </Button>
        </div>
      ) : (
        <Box
          sx={{
            width: "100%", // Ensures the div takes full width of its container
            maxWidth: "500px", // Sets a maximum width to avoid too wide a display on larger screens
            minHeight: "100px", // Minimum height to maintain a consistent size regardless of content
            paddingTop: "20px", // Adds padding for spacing inside the div
            //boxShadow: "0 2px 8px rgba(0,0,0,0.1)", // Optional: adds a shadow for better visual separation
            "@media (max-width: 800px)": {
              maxWidth: "90%", // Allows the box to fill more space on medium-small devices
              padding: "15px", // Slightly smaller padding on medium-small devices
            },
            "@media (max-width: 600px)": {
              maxWidth: "150px", // Takes full width on smaller screens
              padding: "10px", // Smaller padding for smaller screens
              // boxShadow: "0 1px 4px rgba(0,0,0,0.1)", // Lighter shadow on smaller screens for subtlety
            },
            "@media (max-width: 400px)": {
              maxWidth: "100px",
              padding: "10px", // Smaller padding for smaller screens
              // boxShadow: "0 1px 4px rgba(0,0,0,0.1)", // Lighter shadow on smaller screens for subtlety
            },
            "@media (max-width: 300px)": {
              maxWidth: "40px",
              padding: "10px", // Smaller padding for smaller screens
              // boxShadow: "0 1px 4px rgba(0,0,0,0.1)", // Lighter shadow on smaller screens for subtlety
            },
          }}
        >
          {role == "broker" ? (
            <Autocomplete
              id="group-select"
              value={selectedPayee}
              onChange={(event, newValue) => {
                setSelectedPayee(newValue || "");
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={payees || []}
              renderInput={(params) => <TextField {...params} label="Selected Broker:" variant="outlined" />}
              renderOption={(props, option) => (
                <li {...props}>
                  {option} - {payeeNames[option.replace(/\s/g, "")] ?? ""}
                </li>
              )}
              getOptionLabel={(option) => `${option} - ${payeeNames[option.replace(/\s/g, "")] ?? ""}`}
              sx={{
                minWidth: 200, // Minimum width for the FormControl
                maxWidth: "100%", // Ensures the FormControl does not exceed the container width
                background: "#f2f2f2",
                "& .MuiSelect-select": {
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "100%", // Prevents overflow and ensures good visibility on smaller screens
                },
                "@media (max-width: 800px)": {
                  "& .MuiSelect-select": {
                    fontSize: "0.95rem", // Moderately small font size for medium-small screens
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.95rem", // Slightly smaller label font size for medium-small screens
                  },
                },
                "@media (max-width: 500px)": {
                  "& .MuiSelect-select": {
                    fontSize: "0.875rem", // Smaller font size for smaller screens
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.875rem", // Smaller label font size for consistency on smaller screens
                  },
                  minWidth: 80,
                },
                "@media (max-width: 300px)": {
                  "& .MuiSelect-select": {
                    fontSize: "0.875rem", // Smaller font size for smaller screens
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.875rem", // Smaller label font size for consistency on smaller screens
                  },
                  minWidth: 50,
                },
              }}
            />
          ) : role == "employer" ? (
            <Typography>Group: {payeeNames[selectedPayee]}</Typography>
          ) : (
            <></>
          )}
        </Box>
      )}
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: 650,
            width: "100%", // Updated to 100%
            backgroundColor: "#f2f2f2",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "1px solid #e0e0e0",
            },
            "& .MuiDataGrid-columnHeaders": {
              width: "100%",
              backgroundColor: "#004e9d",
              color: "#ffffff",
            },
            "& .MuiButton-text": {
              color: "#004e9d",
            },
          }}
        >
          <DataGrid
            sx={{
              height: "auto", // Allows DataGrid to grow with content up to the Box height
              width: "100%", // Ensures DataGrid uses full width of the Box, considering padding
              "& .MuiDataGrid-virtualScroller": {
                marginTop: "0 !important", // Adjusts the header alignment issue
              },
            }}
            rows={commissions}
            columns={headCells}
            getRowId={(commissions) => commissions.name}
            disableColumnSelector
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5]}
            slots={{ toolbar: GridToolbar }}
            disableRowSelectionOnClick
          />
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default Commissions;
