import React, { useState, useContext, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { app, host } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import "./UserSearch.css";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Modal from "react-modal";
import CustomAlert from "components/alert/CustomAlert";
// import PayeeModal from "components/admin/PayeeModal";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//import MaintenanceIcon from '@mui/icons-material/Maintenance';
import ViewListIcon from "@mui/icons-material/ViewList";
//import ClaimIcon from '@mui/icons-material/Claim';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PaymentIcon from "@mui/icons-material/Payment";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SearchIcon from "@mui/icons-material/Search";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TableChartIcon from "@mui/icons-material/TableChart";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PayeeModal from "./PayeeModal";

// Firebase Authentication setup
const auth = getAuth();

interface UserData {
  id: string;
  data: any;
}

interface Payee {
  inputValue?: string;
  Name: string;
  Federal_ID?: string;
  Group_ID?: string;
}

const UserSearch: React.FC = () => {
  // Initialize component state variables
  const [searchEmail, setSearchEmail] = useState<string>("");
  const [searchFirstName, setSearchFirstName] = useState<string>("");
  const [user, setUser] = useState<UserData | null>(null);
  const [searched, setSearched] = useState<boolean>(false);
  const [searchedFirstNameVeri, setSearchedFirstNameVeri] = useState<boolean>(false);
  const [newRole, setNewRole] = useState<string>("employer");
  const [isSuspended, setIsSuspended] = useState<boolean>(false);
  const [viewCommissions, setViewCommissions] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [requestedGroupName, setRequestedGroupName] = useState<string>("");
  const [requestedAgencyName, setRequestedAgencyName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPayees, setCurrentPayees] = useState([]);
  const [formattedPayees, setFormattedPayees] = useState([]);
  const [payees, setPayees] = useState<Payee[]>([]);
  const [groupsList, setGroupsList] = useState([]);
  const [overrideTaxId, setOverrideTaxId] = useState("");
  const [users, setUsers] = useState([]);

  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isAlertOpen, setAlertOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [disableOverride, setDisableOverride] = useState<boolean>(true);

  const [emailVerificationLink, setEmailVerificationLink] = useState<string>("");

  const [showFallbackLink, setShowFallbackLink] = useState(false);

  const [userSearchVerification, setuserSearchVerification] = useState(false);

  const [triggerEmailVerification, setTriggerEmailVerification] = useState(false);

  const [disable, setDisable] = useState(Boolean); // Initialize the state

  const [openTileAccess, setOpenTileAccess] = useState(false); // Rename or repurpose this if it's specifically for another modal
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

  const closeAlert = () => {
    setAlertOpen(false);
  };
  // Get Firestore instance
  const db = getFirestore(app);

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser } = authContext; // Get current user from Auth context

  let tk: string;

  currentUser?.getIdToken(false).then((idToken) => {
    tk = idToken;
  });

  const navigate = useNavigate();

  const handleSearch = async () => {
    setSearched(false);
    setuserSearchVerification(false);
    //setEmailVerificationLink("");
    setUser(null);
    const q = query(collection(db, "user-info"), where("Email", "==", searchEmail));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        setUser({ id: doc.id, data: doc.data() });
        setIsSuspended(doc.data().Suspended || false);
        setViewCommissions(doc.data().View_Commissions || false);
        setNewRole(doc.data().Role);
        setRequestedGroupName(doc.data().Requested_Group_Name);
        setGroupsList(doc.data().Group_numbers);
        setSelectedKeywords(doc.data().tileAccess || []);
        setCurrentPayees(doc.data().Payees || []);
      });
      setTriggerEmailVerification((prev) => !prev);
    }

    setSearched(true);
    setSearchedFirstNameVeri(false);
  };

  const handleSearchByFirstName = async () => {
    setSearchedFirstNameVeri(false);

    // Trim the input and prepare both original, lowercase, and uppercase versions
    const originalName = searchFirstName.trim();
    const lowerName = originalName.toLowerCase();
    const upperName = originalName.toUpperCase();
    const titleName = originalName.charAt(0).toUpperCase() + originalName.slice(1).toLowerCase();

    // Define a set to track unique user IDs to avoid duplicate entries
    const foundUserIds = new Set();
    const foundUsers = [];

    // Helper function to process query results
    const processQuery = async (name) => {
      const q = query(collection(db, "user-info"), where("First_name", "==", name));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          if (!foundUserIds.has(doc.id)) {
            foundUsers.push({ id: doc.id, data: doc.data() });
            foundUserIds.add(doc.id);
          }
        });
      }
    };

    // Execute queries for original, lowercase, and uppercase names
    await processQuery(searchFirstName);
    if (searchFirstName !== originalName) {
      await processQuery(originalName);
    }
    if (originalName !== lowerName) {
      await processQuery(lowerName);
    }
    if (
      originalName !== upperName &&
      lowerName !== upperName &&
      searchFirstName !== upperName &&
      titleName !== upperName
    ) {
      await processQuery(upperName);
    }
    if (
      titleName !== originalName &&
      titleName !== lowerName &&
      titleName !== upperName &&
      titleName !== searchFirstName
    ) {
      await processQuery(titleName);
    }

    setUsers(foundUsers);
    setSearchedFirstNameVeri(true);
  };

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      checkEmailVerification();
    }, 500); // Delaying checkEmailVerification by 500 milliseconds

    return () => clearTimeout(timer); // Cleanup to clear the timer if the component unmounts
  }, [triggerEmailVerification]);

  const handleRoleChange = async () => {
    let data = {
      userId: user?.id,
      role: newRole,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/CData3`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        handleSearch();
        setIsLoading(false);

        try {
        } catch (error) {
          console.error("Error:", error);
          setIsLoading(false);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateEmailVerification = async () => {
    let data = {
      userId: user?.id,
      email: user?.data.Email,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/emailGen`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        if (data.Link) {
          // Assuming 'link' is the property containing the verification link

          setEmailVerificationLink(data.Link);
        }

        handleSearch();
        setIsLoading(false);

        try {
        } catch (error) {
          console.error("Error:", error);
          setIsLoading(false);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkEmailVerification = async () => {
    let data = {
      userId: user?.id,
      email: user?.data.Email,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/emailVerification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        setuserSearchVerification(data.Verification);
        setIsLoading(false);

        try {
        } catch (error) {
          console.error("Error:", error);
          setIsLoading(false);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSuspendChange = async () => {
    const newSuspendedStatus = !isSuspended;
    setIsSuspended(newSuspendedStatus);

    let data = {
      userId: user?.id,
      suspended: newSuspendedStatus,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/CData4`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        handleSearch();

        try {
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleTerminateUser = async () => {
    if (window.confirm("Are you sure you want to terminate this user? This action cannot be undone.")) {
      if (user) {
        setIsLoading(true);
        // await deleteDoc(doc(db, "user-info", user.id));

        fetch(`${host}/api/firebase/api/users/${user.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${tk}`,
          },
        })
          .then((response) => response.json())
          .then(async () => {
            handleSearch();
            setIsLoading(false);
          })
          .catch((error) => console.error("Error:", error))
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handlePasswordReset = async () => {
    if (newPassword.length < 6) {
      alert("Password must be at least 6 characters long.");
      return;
    }

    if (user) {
      var pass1 = newPassword;
      var pass2 = user.data.Last_pwd1;
      var pass3 = user.data.Last_pwd2;
      var pass4 = user.data.Last_pwd3;

      let resetData = {
        uid: user.id,
        password: newPassword,
        pass1: pass1,
        pass2: pass2,
        pass3: pass3,
        pass4: pass4,
      };

      setIsLoading(true);

      fetch(`${host}/api/firebase/api/resetData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify(resetData),
      })
        .then((response) => response.json())
        .then(async () => {
          handleSearch();
          setIsLoading(false);
        })
        .catch((error) => console.error("Error:", error))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const goToDashboardPortal = () => {
    navigate("/basePage");
  };

  const emailLinkSignOn = async (email: string) => {
    let EmailFile = {
      Email: email,
    };

    console.log("Button clicked");

    fetch(`${host}/api/firebase/api/EmailLinkSignOn`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`, // Include your auth token if needed
      },
      body: JSON.stringify({ EmailFile }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
        } else {
          throw new Error(data.error || "Unknown error");
        }
        handleSearch();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateTileAccess = async () => {
    const keywordsToAdd = selectedKeywords;

    let tileFile = {
      uid: user.id,
      tiles: keywordsToAdd,
    };

    setIsLoading(true); // Assuming you manage loading state

    fetch(`${host}/api/firebase/api/tileAccess`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`, // Include your auth token if needed
      },
      body: JSON.stringify({ tileFile }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
        } else {
          throw new Error(data.error || "Unknown error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
        window.location.reload();
      });
  };

  const handleDisableMFA = async (uid: string) => {
    setIsLoading(true);

    fetch(`${host}/api/firebase/api/disableMFA`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify({ uid: uid }),
    })
      .then((response) => response.json())
      .then(async (data: any) => {
        handleSearch();

        try {
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      })
      .catch((error: any) => console.error("Error:", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkforPrev = () => {
    let prevDisable = user.data.MFAException;

    if (prevDisable === undefined || prevDisable === null) {
      prevDisable = true;
    }

    return prevDisable;
  };

  const shutdownMFA = async (uid: string, exceptionChecker = null) => {
    setIsLoading(true); // Assuming you manage loading state

    let prevDisable = checkforPrev();

    //setDisable(!prevDisable); // Toggle the disable state based on the previous value

    let updatedDisable;

    if (exceptionChecker === null) {
      updatedDisable = !prevDisable;
    } else {
      updatedDisable = exceptionChecker;
    }

    if (updatedDisable === true) {
      handleDisableMFA(uid);
    }

    let MFAFile = {
      uid: user.id,
      mfaCheck: updatedDisable,
    };

    fetch(`${host}/api/firebase/api/MFAShutDown`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`, // Include your auth token if needed
      },
      body: JSON.stringify({ MFAFile }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          console.log(data.message);
        } else {
          throw new Error(data.error || "Unknown error");
        }
        handleSearch();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkforPrevSignOn = () => {
    let prevDisableLink = user.data.LinkSignOnCheck;

    if (prevDisableLink === undefined || prevDisableLink === null) {
      prevDisableLink = true;
    }

    return prevDisableLink;
  };

  const enableLinkSignIn = async (uid: string) => {
    setIsLoading(true);

    let prevDisableLink = checkforPrevSignOn();

    const updatedDisableLink = !prevDisableLink;

    if (updatedDisableLink === true) {
      await shutdownMFA(uid, true); // Await the result
    } else {
      await shutdownMFA(uid, false); // Await the result
    }

    let LinkFile = {
      uid: user.id,
      LinkSignOnCheck: updatedDisableLink,
    };

    fetch(`${host}/api/firebase/api/enableLinkSignIn`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify({ LinkFile }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
        } else {
          throw new Error(data.error || "Unknown error");
        }
        handleSearch();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGhostUser = async () => {
    //NEED TO IMPLEMENT MORE LEGITAMATE METHOD USING BACKEND SUPPORT AND SUCH
    if (!user) {
      alert("User not found.");
      return;
    }

    setIsLoading(true);

    try {
      fetch(`${host}/api/firebase/api/createCustomToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify({ userId: user.id }),
      })
        .then((response) => response.json())
        .then(async (data: any) => {
          var trackerToken = data.token;

          signInWithCustomToken(auth, trackerToken)
            .then((userCredential) => {
              // Signed in

              const user = userCredential.user;

              goToDashboardPortal();
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
            });
        })
        .catch((error: any) => console.error("Error:", error))
        .finally(() => {
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCommissionsViewChange = () => {
    const newCommissionsView = !viewCommissions;
    setViewCommissions(newCommissionsView);

    let data = {
      userId: user?.id,
      bool: newCommissionsView,
    };

    setIsLoading(true);

    fetch(`${host}/api/firebase/api/CData5`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
        }
        response
          .json()
          .then(async (data: any) => {
            await handleSearch();

            try {
            } catch (error) {
              setIsLoading(false);
              console.error("Error:", error);
            }
          })
          .catch((error: any) => console.error("Error:", error))
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error: any) => console.error("Error: ", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePayeeModalClose = () => {
    setOpen(false);
    handleSearch();
  };

  const modalStyles2 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%", // This line controls the width of the modal
      height: "85%", // This line controls the height of the modal
    },
  };

  const getPayeeInformation = async () => {
    setIsLoading(true);
    let params = new URLSearchParams();
    params.append("currPayees", currentPayees.toString());
    params.append("groups", groupsList.toString());
    params.append("email", searchEmail.toString());
    fetch(`${host}/api/upload/getPossiblePayees?${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response) => {
        setIsLoading(false);
        return response.json();
      })
      .then((responseData) => {
        if (responseData.error) {
          setAlertMessage(responseData.error);
          setAlertOpen(true);
          return;
        }
        setPayees(responseData.payees);
        setOpen(true);
      })
      .catch((error: any) => {
        setAlertMessage(error.message);
        setOpen(true);
        setAlertOpen(true);
        return;
      });
  };

  const fetchPayeeNames = async () => {
    setIsLoading(true);
    const params = new URLSearchParams();
    params.append("payees", currentPayees.toString());

    fetch(`${host}/api/upload/getPayeeNames?${params}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then((response: Response) => {
        setIsLoading(false);
        // if (!response.ok) {
        //   throw new Error(`Big ole issue`);
        // }
        return response.json();
      })
      .then(async (responseData) => {
        if (responseData.error) {
          let newExistingPayees = currentPayees.map((payee) => {
            return payee;
          });
          setFormattedPayees(newExistingPayees);
        } else {
          let newExistingPayees = currentPayees.map((payee) => {
            let found = responseData.payees.find((val) => {
              return payee.replace(/[^0-9]/g, "") == val.Federal_ID.replace(/[^0-9]/g, "");
            });
            if (found) return found.Name.toUpperCase() + " - " + found.Federal_ID;
            return payee + " (NO NAME IN VBA)";
          });
          setFormattedPayees(newExistingPayees);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertMessage(err);
        setAlertOpen(true);
      });
  };

  const copyToClipboardFallback = (text) => {
    // Indicate that the fallback is being used
    setShowFallbackLink(true);
    // Existing fallback logic to manually copy text
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  };

  const keywords = [
    // update this with a default list of keywords
    "Keyword1",
    "Keyword2",
    "Keyword3",
    "Keyword4",
    "cash",
    "Keyword1",
    "Keyword2",
    "Keyword3",
    "Keyword4",
    "cash",
  ]; // Example keywords

  const cardData = [
    {
      title: "Add an Employee",
      iconComponent: <PersonAddIcon className="card-iconHome" />,
    },
    {
      title: "Employee Maintenance",
      iconComponent: <PeopleAltIcon className="card-iconHome" />,
    },
    {
      title: "Download Census",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "Make Payments",
      iconComponent: <PaymentIcon className="card-iconHome" />,
    },
    {
      title: "Claims Look up",
      iconComponent: <SearchIcon className="card-iconHome" />,
    },
    {
      title: "Claim Submission",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "Invoices",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "Policy And Certs",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "OptiEnhance Member Activation",
      iconComponent: <AddCircleOutlineIcon className="card-iconHome" />,
    },
    {
      title: "Commissions",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "Implementation Status",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "Upload Documents",
      iconComponent: <UploadFileIcon className="card-iconHome" />,
    },
    {
      title: "OptiRater Product Quoting",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "Census Upload",
      iconComponent: <TableChartIcon className="card-iconHome" />,
    },
    {
      title: "OptiEnhance Registration Table",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "Admin Control Panel",
      iconComponent: <AdminPanelSettingsIcon className="card-iconHome" />,
    },
  ];

  const cardData2 = [
    {
      title: "/enroll",
      iconComponent: <PersonAddIcon className="card-iconHome" />,
    },
    {
      title: "/employeeMaintenance",
      iconComponent: <PeopleAltIcon className="card-iconHome" />,
    },
    {
      title: "/census",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "/payments",
      iconComponent: <PaymentIcon className="card-iconHome" />,
    },
    {
      title: "/claimsLookup",
      iconComponent: <SearchIcon className="card-iconHome" />,
    },
    {
      title: "Claim Submission",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "/invoice",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "/policyAndCerts",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "OptiEnhance Member Activation",
      iconComponent: <AddCircleOutlineIcon className="card-iconHome" />,
    },
    {
      title: "/claimsCommission",
      iconComponent: <ViewListIcon className="card-iconHome" />,
    },
    {
      title: "/implementationStatus",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "/upload",
      iconComponent: <UploadFileIcon className="card-iconHome" />,
    },
    {
      title: "OptiRater Product Quoting",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
    },
    {
      title: "/censusUpload",
      iconComponent: <TableChartIcon className="card-iconHome" />,
    },
    {
      title: "/optiEnhance",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
    },
    {
      title: "/adminPanel",
      iconComponent: <AdminPanelSettingsIcon className="card-iconHome" />,
    },
  ];

  const cardData3 = [
    {
      title: "Add an Employee",
      iconComponent: <PersonAddIcon className="card-iconHome" />,
      route: "/enroll",
    },
    {
      title: "Employee Maintenance",
      iconComponent: <PeopleAltIcon className="card-iconHome" />,
      route: "/employeeMaintenance",
    },
    {
      title: "Download Census",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/census",
    },
    {
      title: "Make Payments",
      iconComponent: <PaymentIcon className="card-iconHome" />,
      route: "/payments",
    },
    {
      title: "Claims Look up",
      iconComponent: <SearchIcon className="card-iconHome" />,
      route: "/claimsLookup",
    },
    {
      title: "Claim Submission",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
      route: "Claim Submission", // Assuming no change needed here since it matches.
    },
    {
      title: "Invoices",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/invoice",
    },
    {
      title: "Policy And Certs",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/policyAndCerts",
    },
    {
      title: "OptiEnhance Member Activation",
      iconComponent: <AddCircleOutlineIcon className="card-iconHome" />,
      route: "OptiEnhance Member Activation", // Assuming no change needed here since it matches.
    },
    {
      title: "Commissions",
      iconComponent: <ViewListIcon className="card-iconHome" />,
      route: "/claimsCommission",
    },
    {
      title: "Implementation Status",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
      route: "/implementationStatus",
    },
    {
      title: "Upload Documents",
      iconComponent: <UploadFileIcon className="card-iconHome" />,
      route: "/upload",
    },
    {
      title: "OptiRater Product Quoting",
      iconComponent: <AssessmentIcon className="card-iconHome" />,
      route: "OptiRater Product Quoting", // Assuming no change needed here since it matches.
    },
    {
      title: "Census Upload",
      iconComponent: <TableChartIcon className="card-iconHome" />,
      route: "/censusUpload",
    },
    {
      title: "OptiEnhance Registration Table",
      iconComponent: <SystemUpdateAltIcon className="card-iconHome" />,
      route: "/optiEnhance",
    },
    {
      title: "Admin Control Panel",
      iconComponent: <AdminPanelSettingsIcon className="card-iconHome" />,
      route: "/adminPanel",
    },
  ];

  const handleKeywordChange = (title) => {
    setSelectedKeywords((prev) => {
      if (prev.includes(title)) {
        return prev.filter((k) => k !== title);
      } else {
        return [...prev, title];
      }
    });
  };

  const renderKeywordsModal = () => {
    const modalStyles: React.CSSProperties = {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      backgroundColor: "#f7f7f7",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      position: "absolute",
    };

    const tileStyle: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "10px",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      backgroundColor: "#fff",
      cursor: "pointer",
    };

    const buttonStyle: React.CSSProperties = {
      margin: "10px",
      padding: "10px 20px",
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#007BFF",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    };

    return (
      <Modal isOpen={openTileAccess} onRequestClose={() => setOpenTileAccess(false)} style={{ content: modalStyles }}>
        <Tooltip title="Select tiles to display for user, and deselect tile to hide">
          <h2>Select Tiles</h2>
        </Tooltip>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {cardData3.map((item) => (
            <div key={item.title} style={tileStyle} onClick={() => handleKeywordChange(item.route)}>
              <input
                type="checkbox"
                checked={!selectedKeywords.includes(item.route)}
                onChange={() => handleKeywordChange(item.route)}
                id={item.title}
                style={{ marginRight: "5px" }}
              />
              <label htmlFor={item.title}>{item.title}</label>
              {item.iconComponent}
            </div>
          ))}
        </div>
        <button
          onClick={() => {
            updateTileAccess();
            setOpenTileAccess(false);
          }}
          style={buttonStyle}
        >
          Save
        </button>
        <button onClick={() => setOpenTileAccess(false)} style={buttonStyle}>
          Cancel
        </button>
      </Modal>
    );
  };
  const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
      copyToClipboardFallback(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {
        console.error("Async: Could not copy text: ", err);
        copyToClipboardFallback(text);
      }
    );
  };

  // Inside YourComponent

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement; // Type assertion
    input.select();
  };

  const handlePayeeClick = async () => {
    await fetchPayeeNames();
    await getPayeeInformation();
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="user-search-container">
      <input
        type="email"
        value={searchEmail}
        placeholder="Please search for the email here"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchEmail(e.target.value)}
        style={{
          width: "100%", // Changed from 300px to 100%
          padding: "10px",
          margin: "5px 0",
          boxSizing: "border-box",
          border: "2px solid #ccc",
          borderRadius: "4px",
        }}
      />
      <button
        onClick={() => {
          handleSearch();
          setEmailVerificationLink(""); // Add this to clear the email verification link
        }}
      >
        Search
      </button>

      <br />
      <br />

      <h5>Enter First Name here if you would like to see all users with that first name</h5>

      <input
        type="text"
        value={searchFirstName}
        placeholder="Enter first name"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchFirstName(e.target.value)}
        style={{
          width: "100%", // Changed from 300px to 100%
          padding: "10px",
          margin: "5px 0",
          boxSizing: "border-box",
          border: "2px solid #ccc",
          borderRadius: "4px",
        }}
      />
      <button onClick={handleSearchByFirstName}>Search</button>

      {searchedFirstNameVeri && users.length === 0 && <h2>No users found with this first name.</h2>}

      {searchedFirstNameVeri && users.length > 0 && (
        <div>
          <h2>All Users Found with name: {searchFirstName}</h2>
          {users.map((user, index) => (
            <div key={index}>
              <p>First Name: {user.data.First_name}</p>
              <p>Email: {user.data.Email}</p>
              <p>Role: {user.data.Role}</p>
              {/* Display other user details as needed */}
            </div>
          ))}
        </div>
      )}

      {searched && !user && <h2>No user found with this email.</h2>}

      {user && (
        <div>
          <h2>User Found:</h2>
          <p>Email: {user.data.Email}</p>
          <p>Requested Role: {user.data.Requested_Role}</p>
          <p>Role: {user.data.Role}</p>
          <select value={newRole} onChange={(e: ChangeEvent<HTMLSelectElement>) => setNewRole(e.target.value)}>
            <option value="internal_ugp">Internal UGP</option>
            <option value="admin">Admin</option>
            <option value="broker">Broker</option>
            <option value="employer">Employer</option>
          </select>
          <p>Suspended: {isSuspended ? "Yes" : "No"}</p>
          <p>Can View Commissions: {viewCommissions ? "Yes" : "No"}</p>
          <p>MFA disabled: {user.data.MFAException ? "Yes" : "No"}</p>
          <p>Link Sign on Active: {user.data.LinkSignOnCheck ? "Yes" : "No"}</p>
          <button onClick={handleRoleChange}>Change Role</button>
          {user.data.Role === "broker" ? (
            <>
              <button onClick={handleCommissionsViewChange}>
                {viewCommissions ? "Restrict Commissions Viewing" : "Allow to View Comissions"}
              </button>
              <button onClick={handlePayeeClick}>Assign Payees For Commissions</button>
            </>
          ) : null}
          {!userSearchVerification ? (
            <button onClick={generateEmailVerification}>Generate Email verification Link</button>
          ) : (
            <Tooltip title="Use this feature to generate an email verification link if user is unable to see verification email in their inbox">
              <button>Email has already been verified</button>
            </Tooltip>
          )}

          {/*<button onClick={apiCall}>API Call</button>*/}
          {emailVerificationLink && (
            <div>
              <p>Email Verification Link:</p>
              <div style={{ maxWidth: "100%", overflowWrap: "break-word", wordBreak: "break-all" }}>
                <Tooltip title="Copies Link. Opening the link will verify the email. Send link to user">
                  <a
                    onClick={() => copyToClipboard(emailVerificationLink)}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>Copy Link</span>
                    <ContentCopyIcon onClick={() => copyToClipboard(emailVerificationLink)} style={{ color: "gray" }} />
                  </a>
                </Tooltip>
                {showFallbackLink && (
                  <Tooltip title="If you are having issues copying the link, feel free to send this URL">
                    <div style={{ marginTop: "10px" }}>
                      <p>Copy this link:</p>
                      <input
                        type="text"
                        value={emailVerificationLink}
                        readOnly
                        style={{ width: "100%" }}
                        onClick={handleInputClick}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          )}

          <button onClick={() => setOpenTileAccess(true)}>Update Tile Access</button>
          <br />

          <p>Password:</p>
          <input
            type="password"
            value={newPassword}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
          />
          <button onClick={handlePasswordReset}>Reset Password</button>

          <button onClick={handleSuspendChange}>{isSuspended ? "Unsuspend User" : "Suspend User"}</button>
          <button onClick={handleTerminateUser}>Terminate User</button>
          <Tooltip title="If the user has not set up MFA or hasn't confirmed their email, you may not be able to ghost">
            <button onClick={handleGhostUser}>Ghost User</button>
          </Tooltip>

          <button onClick={() => handleDisableMFA(user.id)}>Reset MFA</button>

          {/* <button onClick={() => shutdownMFA(user.id)}>
            {user.data.MFAException ? "Re-enable MFA for this user" : "Disable MFA for this user"}
          </button> */}

          {/* <button onClick={() => emailLinkSignOn(user.data.Email)}>Generate Email sign in</button> */}

          <button onClick={() => enableLinkSignIn(user.id)}>
            {user.data.LinkSignOnCheck ? "Disable Link Sign In" : "Enable Link Sign In"}
          </button>

          <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />

          <div>{renderKeywordsModal()}</div>

          {open && (
            <Modal isOpen={true} onRequestClose={handlePayeeModalClose} style={modalStyles2}>
              <PayeeModal payees={payees} user={user} outerExistingPayees={formattedPayees} />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSearch;
