import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { app, host } from "../../config";
import { v4 as uuidv4 } from "uuid";

import "./commissionsSystem.css";
import { DataType } from "./commissionsTableComponent";
import CircularProgress from "@mui/material/CircularProgress";
import CommissionsDataGrid from "./commissionsTableComponent";

const auth = getAuth();
const db = getFirestore(app);

const CommissionsLookupSystem: React.FC = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [apiData, setApiData] = useState<Array<DataType>>([]);
  const [showApiData, setShowApiData] = useState(false);
  const [isVBA, setIsVBA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, role, selectedGroup } = authContext;

  const navigate = useNavigate();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(false);
    setShowApiData(true);

    if (!startDate || !endDate) {
      alert("Please enter both start and end dates!");
      return;
    }

    // Check if user has required role
    if (role !== "admin" && role !== "internal_ugp") {
      alert("You are not authorized to make this request");
      return;
    }

    let holder: { [key: string]: any } = {
      startDate: startDate,
      endDate: endDate,
    };

    if (role) {
      holder.role = role;
    }

    setIsLoading(true);

    let params = new URLSearchParams(holder);

    fetch(`${host}/api/Reporting/DataProc/Commissions/PreFlight?${params}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${tk}`,
      },
    })
      .then(async (response: Response) => {
        if (!response.ok) {
          await response.text().then((text) => {
            console.log(text);
            alert(`An error has occured with your query:\n${text}`);
          });
          return;
        } else {
          await response.json().then((res) => {
            setIsLoading(false);
            if (!res.data || res.data.length === 0) {
              alert("No data found for the selected date range");
              return;
            }
            setShowApiData(true);
            const dataWithUidAndFormattedDates = res.data.map((item: any) => {
              if (item.Coverage_End) {
                item.Coverage_End = new Date(item.Coverage_End).toLocaleDateString("en-US", { timeZone: "UTC" });
              }
              if (item.Coverage_Start) {
                item.Coverage_Start = new Date(item.Coverage_Start).toLocaleDateString("en-US", { timeZone: "UTC" });
              }
              return {
                ...item,
                uid: uuidv4(),
              };
            });
            setApiData(dataWithUidAndFormattedDates);
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        alert("An error has occurred when fetching the Pre-Flight Report. Please contact IT.");
      })
      .finally(() => {
        setIsLoading(false);
        console.log("Processing finished");
      });
  };

  const goBack = () => {
    setShowApiData(false);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  if (showApiData) {
    return (
      <div className="App">
        <header className="app-header">
          <div className="header">
            <h1>Commissions</h1>
          </div>
          <div className="header-controls">
            <button className="LossRatio-back-button" onClick={goBack}>
              Back
            </button>

            <div className="compact-date-range">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="compact-date-input"
              />
              <span>to</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="compact-date-input"
              />

              <button onClick={handleSubmit} className="compact-update-btn">
                Update Range
              </button>
            </div>
          </div>
        </header>
        {isVBA && tk ? (
          <>
            <CommissionsDataGrid requestType="dateRange" data={apiData} />
          </>
        ) : (
          <>
            <CommissionsDataGrid requestType="dateRange" data={apiData} />
          </>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div className="header">
          <h1>Commissions</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="filter-section">
            <h3>Select Date Range</h3>
            <div className="date-range-inputs">
              <div>
                <label htmlFor="startDate">Start Date</label>
                <input id="startDate" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              </div>
              <div>
                <label htmlFor="endDate">End Date</label>
                <input id="endDate" type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              </div>
            </div>
          </div>

          <button type="submit">Pre-Flight</button>
        </form>
      </div>
    );
  }
};

export default CommissionsLookupSystem;
