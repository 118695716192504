import React, { useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";

import "./MUIstyling.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import { Navigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";

export type DataType = {
  uid: string; // Unique identifier
  Claims: number;
  Premium: number;
  LossRatio: number;
  Coverage_Start: string;
  Coverage_End: string;
  Group_ID: number;
  Plan_ID: number;
};

declare module "@mui/material/styles" {
  interface Components {
    // This should extend the existing MUI theme types with the new `MuiDataGrid` component.
    MuiDataGrid?: {
      styleOverrides?: {
        // Your style overrides here
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white"; // Default color
            "&:hover": {
              color: "white"; // Change to white on hover
            };
          };
        };
      };
    };
  }
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white", // For other icon buttons
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "white",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          color: "black",
        },
        select: {
          color: "black",
        },
        actions: {
          "& .MuiIconButton-root": {
            color: "black", // Ensures the pagination arrows are always black
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
              color: "black", // Keeps the icon black on hover
            },
          },
        },
      },
    },
  },
});

const auth = getAuth();

export const LossRatioDataGrid = (props: { requestType: string; data: DataType[] }) => {
  const filteredRows = props.data;
  const requestType = props.requestType;

  const [isLoading, setIsLoading] = React.useState(false);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, role, selectedGroup } = authContext;

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const headCells: GridColDef[] = [
    {
      field: "Group_ID",
      headerName: "Group ID",
      width: 230,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "Plan_ID",
      headerName: "Plan ID",
      width: 230,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "Claims",
      headerName: "Total Claims",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "Premium",
      headerName: "Total Premiums",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "LossRatio",
      headerName: "Loss Ratio",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
    },
    {
      field: "Coverage_Start",
      headerName: "Start Date",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Coverage_End",
      headerName: "End Date",
      width: 250,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
  ];

  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 650,
          width: "89%", // Updated to 100%
          backgroundColor: "#f8f8f8",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Enhanced shadow
          padding: "20px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: "#004e9d",
            color: "#ffffff",
            padding: "10px 20px",
            boxShadow: "0 3px 6px rgba(0,0,0,0.2)", // Enhanced shadow
            fontWeight: "bold",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid #e0e0e0",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            color: "#333",
          },
        }}
      >
        <DataGrid
          sx={{
            height: "auto", // Allows DataGrid to grow with content up to the Box height
            width: "100%", // Ensures DataGrid uses full width of the Box, considering padding
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important", // Adjusts the header alignment issue
            },
          }}
          rows={filteredRows}
          columns={headCells}
          getRowId={(filteredRows) => filteredRows.uid}
          disableColumnSelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          slots={{ toolbar: GridToolbar }}
          disableRowSelectionOnClick
        />
      </Box>
    </ThemeProvider>
  );
};

export default LossRatioDataGrid;
