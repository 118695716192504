import React, { useContext, useState } from "react";
import {
  TextField,
  Divider,
  Button,
  Box,
  Grid,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Typography,
  createFilterOptions,
  CircularProgress,
} from "@mui/material";
// import "./main.css";
import { host } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import CustomAlert from "components/alert/CustomAlert";

interface Payee {
  inputValue?: string;
  Name: string;
  Federal_ID?: string;
  Group_ID?: string;
}

interface UserData {
  id: string;
  data: any;
}

interface Props {
  user: UserData;
  payees: Payee[];
  outerExistingPayees: any[];
}

const PayeeModal: React.FC<Props> = ({ user, payees, outerExistingPayees }) => {
  const [existingPayees, setExistingPayees] = React.useState(outerExistingPayees);
  const [selectedPayee, setSelectedPayee] = React.useState<Payee | null>(null);
  const [dialogOpen, toggleDialogOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({
    Name: "",
    Federal_ID: "",
    Group_ID: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const filter = createFilterOptions<Payee>();

  const closeAlert = () => {
    setAlertOpen(false);
  };

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser } = authContext; // Get current user from Auth context

  let tk: string;

  currentUser?.getIdToken(false).then((idToken) => {
    tk = idToken;
  });

  const removeOldPayee = (i: number) => {
    let oldPayees = [...existingPayees];
    oldPayees.splice(i, 1);
    setExistingPayees(oldPayees);
  };

  const onClickAdd = () => {
    if (selectedPayee) {
      setExistingPayees([...existingPayees, selectedPayee.Name + " - " + selectedPayee.Federal_ID]);
      setSelectedPayee({ Name: "", Federal_ID: "", Group_ID: "" });
    } else {
      setAlertMessage(`Can not add nothing as a payee`);
      setAlertOpen(true);
    }
  };

  const handleClose = () => {
    setDialogValue({
      Name: "",
      Federal_ID: "",
      Group_ID: "",
    });
    toggleDialogOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSelectedPayee({
      Name: dialogValue.Name,
      Federal_ID: dialogValue.Federal_ID,
      Group_ID: dialogValue.Group_ID,
    });
    handleClose();
  };

  const savePayees = () => {
    setIsLoading(true);
    setOpen(false);

    const cleanPayeeList = existingPayees.map((payee) => {
      return payee
        .split("-")
        .splice(-1)[0]
        .replace(/[^0-9 ]/g, "")
        .replace(" ", "");
    });

    // this is if the user doesnt hit add before saving
    if (selectedPayee?.Federal_ID) {
      setExistingPayees([...existingPayees, selectedPayee.Name + " - " + selectedPayee.Federal_ID]);
      setSelectedPayee({ Name: "", Federal_ID: "", Group_ID: "" });
      cleanPayeeList.push(selectedPayee.Federal_ID);
    }

    let data = {
      userId: user?.id,
      payeeIds: cleanPayeeList,
    };

    fetch(`${host}/api/firebase/api/UpdateUserPayees`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tk}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Issue processing this request. Please try again later.`);
        }
        return response.json();
      })
      .then((responseData) => {
        setIsLoading(false);
        setAlertMessage(`We have updated the brokers/agencies the user is assigned to`);
        setAlertOpen(true);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertMessage(err.message);
        setAlertOpen(true);
      });
  };
  if (isLoading) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Grid>
        {existingPayees && existingPayees.length > 0 ? <Divider>Assigned Brokers/Agencies</Divider> : null}
        {existingPayees.map((element, index) => {
          return (
            <Grid container alignItems="center" spacing={2} sx={{ marginBottom: "0.75rem" }}>
              <Grid item xs={8}>
                <TextField
                  sx={{ width: "inherit" }}
                  type="text"
                  name="name"
                  key={element}
                  defaultValue={element}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                {existingPayees.length > 0 ? (
                  <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => removeOldPayee(index)}
                      sx={{ backgroundColor: "#004e9d", width: 100 }}
                    >
                      {" "}
                      Remove
                    </Button>
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: "0.75rem" }}
        >
          <Typography id="Payees-modal-title" variant="h6" component="h2">
            Please select a Broker/Agency
          </Typography>
          <Grid container alignItems="center" spacing={2} sx={{ marginBottom: "0.75rem" }}>
            <Grid item>
              <Autocomplete
                disablePortal
                id="Payee-autocomplete"
                value={selectedPayee}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setTimeout(() => {
                      toggleDialogOpen(true);
                      setDialogValue({
                        Name: newValue,
                        Federal_ID: "",
                        Group_ID: "",
                      });
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleDialogOpen(true);
                    setDialogValue({
                      Name: newValue.inputValue,
                      Federal_ID: "",
                      Group_ID: "",
                    });
                  } else {
                    setSelectedPayee(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue != "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      Name: `Add "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                options={payees.sort((a, b) => a.Group_ID.localeCompare(b.Group_ID))}
                groupBy={(option) => option.Group_ID}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.Name;
                }}
                renderOption={(props, option) => {
                  return (
                    <li key={option.Name} {...props}>
                      {option.Name}
                    </li>
                  );
                }}
                sx={{ width: 300, marginLeft: "16px" }}
                renderInput={(params) => <TextField {...params} label="Payees" />}
              />
              <Dialog open={dialogOpen} onClose={handleClose}>
                <form className="autocomplete-dialog-form" onSubmit={handleSubmit}>
                  <DialogTitle>Add New Broker/Agency</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      This is so you can override the options given and assign a broker/agency that is not associated
                      with the users groups.
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      value={dialogValue.Name}
                      onChange={(event) =>
                        setDialogValue({
                          ...dialogValue,
                          Name: event.target.value,
                        })
                      }
                      label="Broker/Agency Name"
                      type="text"
                      variant="standard"
                      sx={{
                        display: "inline-flex",
                        flexDirection: "column",
                        position: "relative",
                        minWidth: "0px",
                        padding: "0px",
                        margin: "8px 0px 4px",
                        border: "0px",
                        verticalAlign: "top",
                      }}
                    />
                    <TextField
                      margin="dense"
                      id="name"
                      value={dialogValue.Federal_ID}
                      onChange={(event) =>
                        setDialogValue({
                          ...dialogValue,
                          Federal_ID: event.target.value,
                        })
                      }
                      label="Agency Federal ID/Broker SSN"
                      type="number"
                      variant="standard"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button sx={{ color: "#004e9b" }} onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button sx={{ color: "#004e9b" }} type="submit">
                      Add
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Grid>
            <Grid item xs>
              <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ height: "100%" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={onClickAdd}
                  sx={{ backgroundColor: "#004e9d", width: 100 }}
                >
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button variant="contained" onClick={savePayees} sx={{ backgroundColor: "#004e9d", width: 100 }}>
        Save
      </Button>

      <CustomAlert message={alertMessage} isOpen={isAlertOpen} onClose={closeAlert} />
    </>
  );
};

export default PayeeModal;
