import React, { useContext } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRowParams, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import PaymentIcon from "@mui/icons-material/Payment";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

import "./MUIstyling.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import { Navigate } from "react-router-dom";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";

export type DataType = {
  uid: string; // Unique identifier
  Payee_ID: string;
  Name: string;
  Distribution_Amount: number;
  Email_Address: string;
  ID_Code_Data: string;
};

declare module "@mui/material/styles" {
  interface Components {
    // This should extend the existing MUI theme types with the new `MuiDataGrid` component.
    MuiDataGrid?: {
      styleOverrides?: {
        // Your style overrides here
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white"; // Default color
            "&:hover": {
              color: "white"; // Change to white on hover
            };
          };
        };
      };
    };
  }
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          "& .MuiDataGrid-sortIcon": {
            color: "white",
            "&:hover": {
              color: "white",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "white", // For other icon buttons
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
            color: "white",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectIcon: {
          color: "black",
        },
        select: {
          color: "black",
        },
        actions: {
          "& .MuiIconButton-root": {
            color: "black", // Ensures the pagination arrows are always black
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)", // Adds a subtle background on hover
              color: "black", // Keeps the icon black on hover
            },
          },
        },
      },
    },
  },
});

const auth = getAuth();

export const CommissionsDataGrid = (props: { requestType: string; data: DataType[] }) => {
  const filteredRows = props.data;
  const requestType = props.requestType;

  const [isLoading, setIsLoading] = React.useState(false);
  const [rowErrors, setRowErrors] = React.useState<{ [key: string]: string }>({});
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [selections, setSelections] = React.useState<{
    email: { [key: string]: boolean };
    uploadBC: { [key: string]: boolean };
    storeGCP: { [key: string]: boolean };
  }>({
    email: {},
    uploadBC: {},
    storeGCP: {},
  });
  const [isRunning, setIsRunning] = React.useState(false);

  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const { currentUser, suspended, role, selectedGroup } = authContext;

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  var tk: string;
  currentUser
    ?.getIdToken(false)
    .then((idToken: string) => {
      tk = idToken;
    })
    .catch((error: any) => {
      console.log(error);
    });

  if (suspended == true) {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        return <Navigate to="/login" />;
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  }

  const handleSendEmail = async (row: DataType) => {
    try {
      setIsLoading(true);
      setRowErrors((prev) => ({ ...prev, [row.uid]: "" }));

      const response = await fetch("/api/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: row.Email_Address,
          name: row.Name,
          payeeId: row.Payee_ID,
          amount: row.Distribution_Amount,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      setRowErrors((prev) => ({
        ...prev,
        [row.uid]: error instanceof Error ? error.message : "Failed to send email",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendPayment = async (row: DataType) => {
    try {
      setIsLoading(true);
      setRowErrors((prev) => ({ ...prev, [row.uid]: "" }));

      const response = await fetch("/api/send-payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payeeId: row.Payee_ID,
          name: row.Name,
          amount: row.Distribution_Amount,
          email: row.Email_Address,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to send payment");
      }

      alert("Payment sent successfully!");
    } catch (error) {
      console.error("Error sending payment:", error);
      setRowErrors((prev) => ({
        ...prev,
        [row.uid]: error instanceof Error ? error.message : "Failed to send payment",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectionChange = (type: "email" | "uploadBC" | "storeGCP", rowId: string) => {
    setSelections((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [rowId]: !prev[type][rowId],
      },
    }));
  };

  const handleHeaderSelectionChange = (type: "email" | "uploadBC" | "storeGCP") => {
    const allSelected = filteredRows.every((row) => selections[type][row.uid]);
    const newSelections = { ...selections };

    filteredRows.forEach((row) => {
      newSelections[type][row.uid] = !allSelected;
    });

    setSelections(newSelections);
  };

  const getSelectedCount = (type: "email" | "uploadBC" | "storeGCP") => {
    return Object.values(selections[type]).filter(Boolean).length;
  };

  const handlePreFlight = async () => {
    try {
      setIsLoading(true);
      const selectedData = filteredRows.filter((row) => selectedRows.includes(row.uid));

      const response = await fetch("/api/Reporting/DataProc/Commissions/PreFlight", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify({
          rows: selectedData,
          selections: {
            email: selections.email,
            uploadBC: selections.uploadBC,
            storeGCP: selections.storeGCP,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Pre-flight check failed");
      }

      const result = await response.json();
      alert("Pre-flight check completed successfully!");
    } catch (error) {
      console.error("Pre-flight error:", error);
      alert("Pre-flight check failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRun = async () => {
    try {
      setIsRunning(true);
      const selectedData = filteredRows.filter((row) => selectedRows.includes(row.uid));

      const response = await fetch("/api/Reporting/DataProc/Commissions/Run", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${tk}`,
        },
        body: JSON.stringify({
          rows: selectedData,
          selections: {
            email: selections.email,
            uploadBC: selections.uploadBC,
            storeGCP: selections.storeGCP,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Run operation failed");
      }

      const result = await response.json();
      alert("Run operation completed successfully!");
    } catch (error) {
      console.error("Run error:", error);
      alert("Run operation failed. Please try again.");
    } finally {
      setIsRunning(false);
    }
  };

  const headCells: GridColDef[] = [
    {
      field: "Payee_ID",
      headerName: "Payee ID",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Name",
      headerName: "Name",
      width: 120,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Distribution_Amount",
      headerName: "Distribution Amount",
      width: 140,
      editable: false,
      headerClassName: "super-app-theme--header",
      type: "number",
      valueFormatter: ({ value }) => {
        if (value === null) return "";
        return `$${value.toFixed(2)}`;
      },
    },
    {
      field: "Email_Address",
      headerName: "Email Address",
      width: 140,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ID_Code_Data",
      headerName: "ID Code Data",
      width: 140,
      editable: false,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      width: 170,
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
            width: "100%",
          }}
        >
          <Checkbox
            checked={filteredRows.length > 0 && filteredRows.every((row) => selections.email[row.uid])}
            indeterminate={getSelectedCount("email") > 0 && getSelectedCount("email") < filteredRows.length}
            onChange={() => handleHeaderSelectionChange("email")}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "white",
              },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
            }}
          />
          <span style={{ color: "white", fontWeight: "bold", whiteSpace: "nowrap" }}>Email</span>
          <span style={{ color: "white", marginLeft: "auto" }}>
            {getSelectedCount("email") > 0 ? `(${getSelectedCount("email")})` : ""}
          </span>
        </Box>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!selections.email[params.row.uid]}
          onChange={() => handleSelectionChange("email", params.row.uid)}
          sx={{
            color: "#004e9d",
            "&.Mui-checked": {
              color: "#004e9d",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 78, 157, 0.04)",
            },
          }}
        />
      ),
    },
    {
      field: "uploadBC",
      headerName: "Upload BC",
      width: 170,
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
            width: "100%",
          }}
        >
          <Checkbox
            checked={filteredRows.length > 0 && filteredRows.every((row) => selections.uploadBC[row.uid])}
            indeterminate={getSelectedCount("uploadBC") > 0 && getSelectedCount("uploadBC") < filteredRows.length}
            onChange={() => handleHeaderSelectionChange("uploadBC")}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "white",
              },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
            }}
          />
          <span style={{ color: "white", fontWeight: "bold", whiteSpace: "nowrap" }}>Upload BC</span>
          <span style={{ color: "white", marginLeft: "auto" }}>
            {getSelectedCount("uploadBC") > 0 ? `(${getSelectedCount("uploadBC")})` : ""}
          </span>
        </Box>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!selections.uploadBC[params.row.uid]}
          onChange={() => handleSelectionChange("uploadBC", params.row.uid)}
          sx={{
            color: "#004e9d",
            "&.Mui-checked": {
              color: "#004e9d",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 78, 157, 0.04)",
            },
          }}
        />
      ),
    },
    {
      field: "storeGCP",
      headerName: "Store in GCP",
      width: 170,
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
            width: "100%",
          }}
        >
          <Checkbox
            checked={filteredRows.length > 0 && filteredRows.every((row) => selections.storeGCP[row.uid])}
            indeterminate={getSelectedCount("storeGCP") > 0 && getSelectedCount("storeGCP") < filteredRows.length}
            onChange={() => handleHeaderSelectionChange("storeGCP")}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "white",
              },
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
            }}
          />
          <span style={{ color: "white", fontWeight: "bold", whiteSpace: "nowrap" }}>Store in GCP</span>
          <span style={{ color: "white", marginLeft: "auto" }}>
            {getSelectedCount("storeGCP") > 0 ? `(${getSelectedCount("storeGCP")})` : ""}
          </span>
        </Box>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={!!selections.storeGCP[params.row.uid]}
          onChange={() => handleSelectionChange("storeGCP", params.row.uid)}
          sx={{
            color: "#004e9d",
            "&.Mui-checked": {
              color: "#004e9d",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 78, 157, 0.04)",
            },
          }}
        />
      ),
    },
    {
      field: "sendEmail",
      headerName: "Resend",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          onClick={() => handleSendEmail(params.row)}
          disabled={isLoading}
          sx={{
            backgroundColor: "#004e9d",
            "&:hover": {
              backgroundColor: "#003d7a",
            },
          }}
        >
          Send
        </Button>
      ),
    },
    {
      field: "sendPayment",
      headerName: "Rerun",
      width: 150,
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<PaymentIcon />}
          onClick={() => handleSendPayment(params.row)}
          disabled={isLoading}
          sx={{
            backgroundColor: "#004e9d",
            "&:hover": {
              backgroundColor: "#003d7a",
            },
          }}
        >
          Send
        </Button>
      ),
    },
    {
      field: "error",
      headerName: "Error Status",
      width: 200,
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const error = rowErrors[params.row.uid];

        return (
          <Tooltip
            title={error || "No Error"}
            arrow
            placement="top"
            sx={{
              backgroundColor: error ? "error.main" : "success.main",
              "& .MuiTooltip-arrow": {
                color: error ? "error.main" : "success.main",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: error ? "error.main" : "success.main",
                cursor: "help",
              }}
            >
              <ErrorIcon
                fontSize="small"
                sx={{
                  transform: error ? "none" : "rotate(180deg)",
                  color: error ? "error.main" : "success.main",
                }}
              />
              <span>{error ? "Error" : "No Error"}</span>
            </Box>
          </Tooltip>
        );
      },
    },
  ];

  if (isLoading || isRunning) {
    return (
      <div className="loader-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 650,
          width: "89%",
          backgroundColor: "#f8f8f8",
          boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          padding: "20px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {/* <Button
            variant="contained"
            onClick={() => window.history.back()}
            sx={{
              backgroundColor: "#004e9d",
              "&:hover": {
                backgroundColor: "#003d7a",
              },
            }}
          >
            Back
          </Button> */}
          <Button
            variant="contained"
            onClick={handlePreFlight}
            disabled={selectedRows.length === 0 || isLoading}
            sx={{
              backgroundColor: "#004e9d",
              "&:hover": {
                backgroundColor: "#003d7a",
              },
            }}
          >
            Pre Flight
          </Button>
          <Button
            variant="contained"
            onClick={handleRun}
            disabled={selectedRows.length === 0 || isRunning}
            sx={{
              backgroundColor: "#004e9d",
              "&:hover": {
                backgroundColor: "#003d7a",
              },
            }}
          >
            Run
          </Button>
          {(isLoading || isRunning) && <CircularProgress size={24} />}
        </Box>
        <DataGrid
          sx={{
            height: "auto",
            width: "100%",
            "& .MuiDataGrid-virtualScroller": {
              marginTop: "0 !important",
            },
          }}
          rows={filteredRows}
          columns={headCells}
          getRowId={(row) => row.uid}
          checkboxSelection
          disableRowSelectionOnClick={false}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection as string[]);
          }}
          rowSelectionModel={selectedRows}
          disableColumnSelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default CommissionsDataGrid;
