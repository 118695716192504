// Importing necessary packages and components from React, React Router, Reactstrap, and Bootstrap
import React, { useEffect, useState, useContext } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { app } from "../../config";
import { AuthContext } from "../authProvider/authProvider";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore, DocumentData } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeIcon from "@mui/icons-material/Home";

// Importing necessary CSS and image assets
import "bootstrap/dist/css/bootstrap.css";
import "components/assets/Header.css";
import pic from "components/assets/step-1.png";

// Importing icons from React Icons
import { FaSlidersH, FaDatabase, FaSearchengin, FaLaptopMedical } from "react-icons/fa";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";

import { matchSorter } from "match-sorter";

interface HeaderProps {
  toggleNavBar: () => void;
  isNavBarOpen: boolean;
}

// Initialize Firebase Auth and Firestore
const auth = getAuth();
const db = getFirestore(app);

// Header Component
const Header: React.FC<HeaderProps> = ({ toggleNavBar, isNavBarOpen }) => {
  // Using useState to manage the collapsed state of Navbar
  const [collapsed, setCollapsed] = useState(true);

  // Toggle function to open and close the Navbar
  const toggleNavbar = () => setCollapsed(!collapsed);

  // Using the useLocation hook from React Router to get the current page location
  const location = useLocation();

  // State for tracking the current page
  const [currentPage, setCurrentPage] = useState("");

  const [inputValue, setInputValue] = useState("");

  const authContext = useContext(AuthContext); // Get Auth context
  if (!authContext) {
    throw new Error("AuthContext is undefined");
  }
  const {
    role,
    firstName,
    lastName,
    currentUser,
    selectedGroup,
    setSelectedGroup,
    authorizedGroupNumbers,
    groupNames,
  } = authContext; // Get current user from Auth context

  console.log(role);

  const navigate = useNavigate(); // Hook to navigate between routes

  // Link color customization
  const linkStyle = {
    color: "black", // Set your color here
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Signed out");
        goToHome();
      })
      .catch((error) => {
        console.error("Error signing out", error);
      });
  };

  // useEffect to update the current page based on the location path
  useEffect(() => {
    const currentPath = location.pathname;
    setCollapsed(true);
    switch (currentPath) {
      case "/upload":
        setCurrentPage("Document Upload Options");
        break;
      default:
        setCurrentPage("");
    }
  }, [location]); // Re-run this effect when location changes


  const goToAdminPanel = () => {
    navigate("/adminPanel");
  };

  const goToBasePage = () => {
    navigate("/basePage");
  };


  const goToHome = () => {
    navigate("/");
  };

  const goToSignup = () => {
    navigate("/signup");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const goToAcctProfile = () => {
    navigate("/acctProfile");
  };

  const filterOptions = (options: string[], { inputValue }: { inputValue: string }) => {
    return matchSorter(options, inputValue, {
      keys: [(item) => item, (item) => groupNames[item]],
    });
  };

  // Rendering the Header Component
  return (
    <div>
      <Navbar className="customNavbar" color="light">
        <div className="d-flex align-items-center">
          <NavbarBrand>
            <button
              onClick={toggleNavBar}
              style={{
                border: "none",
                background: "none",
                fontSize: "20px",
                color: "black",
              }}
            >
              {isNavBarOpen ? (
                <>
                  <i className="fa fa-arrow-left"></i>
                  <div style={{ fontSize: "12px" }}>hide</div>
                </>
              ) : (
                <>
                  <i className="fa fa-arrow-right"></i>
                  <div style={{ fontSize: "12px" }}>show</div>
                </>
              )}
            </button>

          </NavbarBrand>

          <NavItem className="navItemCustom">
            {currentUser && (
              <NavLink className="navItemCustom" onClick={goToBasePage}>
                <HomeIcon style={{ fontSize: "40px" }} />
              </NavLink>
            )}
          </NavItem>

          <Box
            sx={{
              width: "100%", // Ensures the div takes full width of its container
              maxWidth: "500px", // Sets a maximum width to avoid too wide a display on larger screens
              minHeight: "100px", // Minimum height to maintain a consistent size regardless of content
              margin: "auto", // Centers the div within its parent container
              padding: "20px", // Adds padding for spacing inside the div
              //boxShadow: "0 2px 8px rgba(0,0,0,0.1)", // Optional: adds a shadow for better visual separation
              "@media (max-width: 800px)": {
                maxWidth: "90%", // Allows the box to fill more space on medium-small devices
                padding: "15px", // Slightly smaller padding on medium-small devices
              },
              "@media (max-width: 600px)": {
                maxWidth: "150px", // Takes full width on smaller screens
                padding: "10px", // Smaller padding for smaller screens
                // boxShadow: "0 1px 4px rgba(0,0,0,0.1)", // Lighter shadow on smaller screens for subtlety
              },
              "@media (max-width: 400px)": {
                maxWidth: "100px",
                padding: "10px", // Smaller padding for smaller screens
                // boxShadow: "0 1px 4px rgba(0,0,0,0.1)", // Lighter shadow on smaller screens for subtlety
              },
              "@media (max-width: 300px)": {
                maxWidth: "40px",
                padding: "10px", // Smaller padding for smaller screens
                // boxShadow: "0 1px 4px rgba(0,0,0,0.1)", // Lighter shadow on smaller screens for subtlety
              },
            }}
          >
            {role == "broker" ? (
              <Autocomplete
                id="group-select"
                value={selectedGroup}
                onChange={(event, newValue) => {
                  setSelectedGroup(newValue || "");
                }}
                disabled={location.pathname === "/commissions"} // This is currently the only page we want to disable the selection for, might want to add this to more pages in the future
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={authorizedGroupNumbers || []}
                renderInput={(params) => <TextField {...params} label="Selected Group:" variant="outlined" />}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option} - {groupNames[option]}
                  </li>
                )}
                getOptionLabel={(option) => `${option} - ${groupNames[option]}`}
                filterOptions={filterOptions}
                sx={{
                  minWidth: 200, // Minimum width for the FormControl
                  maxWidth: "100%", // Ensures the FormControl does not exceed the container width
                  "& .MuiSelect-select": {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "100%", // Prevents overflow and ensures good visibility on smaller screens
                  },
                  "@media (max-width: 800px)": {
                    "& .MuiSelect-select": {
                      fontSize: "0.95rem", // Moderately small font size for medium-small screens
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.95rem", // Slightly smaller label font size for medium-small screens
                    },
                  },
                  "@media (max-width: 500px)": {
                    "& .MuiSelect-select": {
                      fontSize: "0.875rem", // Smaller font size for smaller screens
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.875rem", // Smaller label font size for consistency on smaller screens
                    },
                    minWidth: 80,
                  },
                  "@media (max-width: 300px)": {
                    "& .MuiSelect-select": {
                      fontSize: "0.875rem", // Smaller font size for smaller screens
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "0.875rem", // Smaller label font size for consistency on smaller screens
                    },
                    minWidth: 50,
                  },
                }}
              />
            ) : role == "employer" ? (
              <Typography>Group: {groupNames[selectedGroup]}</Typography>
            ) : (
              <></>
            )}
          </Box>

          <div className="parent-container">
            <NavItem className="navItemCustom2">{currentPage}</NavItem>
          </div>
        </div>

        <div>
          <NavbarToggler onClick={toggleNavbar} className="custom-toggler" />
        </div>

        <Collapse navbar isOpen={!collapsed}>
          {" "}
          {/* if we want to go back to left side, just rm the div - collapse containter */}
          <div className="collapse-container">
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink className="navItemCustom" onClick={goToAcctProfile}>
                  {" "}
                  Account Profile <FontAwesomeIcon icon={faUser} />{" "}
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink className="navItemCustom" onClick={handleSignOut}>
                  {" "}
                  Sign Out{" "}
                </NavLink>
              </NavItem>

              <NavItem>
                {currentUser && (
                  <NavLink className="navItemCustom" disabled>
                    Welcome, {firstName}
                  </NavLink>
                )}
              </NavItem>

              {role === "admin" && (
                <NavItem>
                  <NavLink className="navItemCustom" onClick={goToAdminPanel}>
                    Admin
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
